/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Shore Power Hub API
 * Shore Power Hub provides the backend services for Plug Insight. <br><br>The spec is available at \<this URL\>/swagger.json.
 * OpenAPI spec version: 2
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";

import {
  useOrvalAxiosInstance,
  type BodyType,
  type ErrorType,
} from "../../useOrvalAxios";
import type {
  ApitypeAppError2,
  ApitypeChargerTransactionDetailResponse,
  ApitypeChargerTransactionsResponse,
  ApitypeSetChargingTransactionAssetRequest,
  ApitypeSetChargingTransactionOrgRequest,
  ApitypeSetConnectorIDRequest,
  ApitypeSkolemBatteriesResponse,
  ApitypeSkolemDigitalTwinsResponse,
  ApitypeSkolemLocationsResponse,
  ApitypeUpdateChargingTransactionRequest,
  GetallchargertransactionsParams,
  GetdigitaltwinsParams,
  HandlersSkolemRequest,
  LoadbalancerLocation,
} from "./models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Get Batteries
 */
export const useGetBatteriesHook = () => {
  const getBatteries = useOrvalAxiosInstance<ApitypeSkolemBatteriesResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getBatteries(
      { url: `/api/v2/skolem/batteries`, method: "GET", signal },
      options,
    );
  };
};

export const getGetBatteriesQueryKey = () => {
  return [`/api/v2/skolem/batteries`] as const;
};

export const useGetBatteriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBatteriesQueryKey();

  const getBatteries = useGetBatteriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>
  > = ({ signal }) => getBatteries(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBatteriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>
>;
export type GetBatteriesQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get Batteries
 */
export const useGetBatteries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBatteriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all charger transactions
 */
export const useGetallchargertransactionsHook = () => {
  const getallchargertransactions =
    useOrvalAxiosInstance<ApitypeChargerTransactionsResponse>();

  return (
    params?: GetallchargertransactionsParams,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getallchargertransactions(
      {
        url: `/api/v2/skolem/charger-transactions`,
        method: "GET",
        params,
        signal,
      },
      options,
    );
  };
};

export const getGetallchargertransactionsQueryKey = (
  params?: GetallchargertransactionsParams,
) => {
  return [
    `/api/v2/skolem/charger-transactions`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetallchargertransactionsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>
  >,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetallchargertransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetallchargertransactionsQueryKey(params);

  const getallchargertransactions = useGetallchargertransactionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>>
  > = ({ signal }) => getallchargertransactions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetallchargertransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>>
>;
export type GetallchargertransactionsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get all charger transactions
 */
export const useGetallchargertransactions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>
  >,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetallchargertransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetallchargertransactionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetallchargertransactionsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get charger transaction
 */
export const useGetchargertransactionHook = () => {
  const getchargertransaction =
    useOrvalAxiosInstance<ApitypeChargerTransactionDetailResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getchargertransaction(
      {
        url: `/api/v2/skolem/charger-transactions/${id}`,
        method: "GET",
        signal,
      },
      options,
    );
  };
};

export const getGetchargertransactionQueryKey = (id: string) => {
  return [`/api/v2/skolem/charger-transactions/${id}`] as const;
};

export const useGetchargertransactionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetchargertransactionQueryKey(id);

  const getchargertransaction = useGetchargertransactionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>
  > = ({ signal }) => getchargertransaction(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetchargertransactionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>
>;
export type GetchargertransactionQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get charger transaction
 */
export const useGetchargertransaction = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetchargertransactionHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetchargertransactionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get digital twins
 */
export const useGetdigitaltwinsHook = () => {
  const getdigitaltwins =
    useOrvalAxiosInstance<ApitypeSkolemDigitalTwinsResponse>();

  return (
    params?: GetdigitaltwinsParams,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getdigitaltwins(
      { url: `/api/v2/skolem/digitaltwins`, method: "GET", params, signal },
      options,
    );
  };
};

export const getGetdigitaltwinsQueryKey = (params?: GetdigitaltwinsParams) => {
  return [`/api/v2/skolem/digitaltwins`, ...(params ? [params] : [])] as const;
};

export const useGetdigitaltwinsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetdigitaltwinsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetdigitaltwinsQueryKey(params);

  const getdigitaltwins = useGetdigitaltwinsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>
  > = ({ signal }) => getdigitaltwins(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetdigitaltwinsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>
>;
export type GetdigitaltwinsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get digital twins
 */
export const useGetdigitaltwins = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetdigitaltwinsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetdigitaltwinsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Do skolem action
 */
export const useDoskolemactionHook = () => {
  const doskolemaction = useOrvalAxiosInstance<void>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return doskolemaction(
      { url: `/api/v2/skolem/do`, method: "POST" },
      options,
    );
  };
};

export const useDoskolemactionMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const doskolemaction = useDoskolemactionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    void
  > = () => {
    return doskolemaction(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DoskolemactionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>
>;

export type DoskolemactionMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Do skolem action
 */
export const useDoskolemaction = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useDoskolemactionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get lbm locations
 */
export const useGetlbmlocationsHook = () => {
  const getlbmlocations =
    useOrvalAxiosInstance<ApitypeSkolemLocationsResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getlbmlocations(
      { url: `/api/v2/skolem/lbm/locations`, method: "GET", signal },
      options,
    );
  };
};

export const getGetlbmlocationsQueryKey = () => {
  return [`/api/v2/skolem/lbm/locations`] as const;
};

export const useGetlbmlocationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetlbmlocationsQueryKey();

  const getlbmlocations = useGetlbmlocationsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>
  > = ({ signal }) => getlbmlocations(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetlbmlocationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>
>;
export type GetlbmlocationsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get lbm locations
 */
export const useGetlbmlocations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetlbmlocationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get lbm location
 */
export const useGetlbmlocationHook = () => {
  const getlbmlocation = useOrvalAxiosInstance<LoadbalancerLocation>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getlbmlocation(
      { url: `/api/v2/skolem/lbm/locations/${id}`, method: "GET", signal },
      options,
    );
  };
};

export const getGetlbmlocationQueryKey = (id: string) => {
  return [`/api/v2/skolem/lbm/locations/${id}`] as const;
};

export const useGetlbmlocationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetlbmlocationQueryKey(id);

  const getlbmlocation = useGetlbmlocationHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>
  > = ({ signal }) => getlbmlocation(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetlbmlocationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>
>;
export type GetlbmlocationQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get lbm location
 */
export const useGetlbmlocation = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetlbmlocationQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Do skolem request
 */
export const useDoskolemrequestHook = () => {
  const doskolemrequest = useOrvalAxiosInstance<void>();

  return (
    handlersSkolemRequest: BodyType<HandlersSkolemRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return doskolemrequest(
      {
        url: `/api/v2/skolem/request`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: handlersSkolemRequest,
      },
      options,
    );
  };
};

export const useDoskolemrequestMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    TError,
    { data: BodyType<HandlersSkolemRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
  TError,
  { data: BodyType<HandlersSkolemRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const doskolemrequest = useDoskolemrequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    { data: BodyType<HandlersSkolemRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return doskolemrequest(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DoskolemrequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>
>;
export type DoskolemrequestMutationBody = BodyType<HandlersSkolemRequest>;
export type DoskolemrequestMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Do skolem request
 */
export const useDoskolemrequest = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    TError,
    { data: BodyType<HandlersSkolemRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useDoskolemrequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set charger Asset
 */
export const useSetchargerAssetHook = () => {
  const setchargerAsset = useOrvalAxiosInstance<void>();

  return (
    apitypeSetChargingTransactionAssetRequest: BodyType<ApitypeSetChargingTransactionAssetRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return setchargerAsset(
      {
        url: `/api/v2/skolem/set-charger-asset`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: apitypeSetChargingTransactionAssetRequest,
      },
      options,
    );
  };
};

export const useSetchargerAssetMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetchargerAssetHook>>>,
    TError,
    { data: BodyType<ApitypeSetChargingTransactionAssetRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetchargerAssetHook>>>,
  TError,
  { data: BodyType<ApitypeSetChargingTransactionAssetRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const setchargerAsset = useSetchargerAssetHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetchargerAssetHook>>>,
    { data: BodyType<ApitypeSetChargingTransactionAssetRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return setchargerAsset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetchargerAssetMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetchargerAssetHook>>>
>;
export type SetchargerAssetMutationBody =
  BodyType<ApitypeSetChargingTransactionAssetRequest>;
export type SetchargerAssetMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Set charger Asset
 */
export const useSetchargerAsset = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetchargerAssetHook>>>,
    TError,
    { data: BodyType<ApitypeSetChargingTransactionAssetRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useSetchargerAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set charger transaction organization
 */
export const useSetchargertransactionorganizationHook = () => {
  const setchargertransactionorganization = useOrvalAxiosInstance<void>();

  return (
    apitypeSetChargingTransactionOrgRequest: BodyType<ApitypeSetChargingTransactionOrgRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return setchargertransactionorganization(
      {
        url: `/api/v2/skolem/set-charger-transaction-org`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: apitypeSetChargingTransactionOrgRequest,
      },
      options,
    );
  };
};

export const useSetchargertransactionorganizationMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useSetchargertransactionorganizationHook>>
    >,
    TError,
    { data: BodyType<ApitypeSetChargingTransactionOrgRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useSetchargertransactionorganizationHook>>
  >,
  TError,
  { data: BodyType<ApitypeSetChargingTransactionOrgRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const setchargertransactionorganization =
    useSetchargertransactionorganizationHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useSetchargertransactionorganizationHook>>
    >,
    { data: BodyType<ApitypeSetChargingTransactionOrgRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return setchargertransactionorganization(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetchargertransactionorganizationMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useSetchargertransactionorganizationHook>>
  >
>;
export type SetchargertransactionorganizationMutationBody =
  BodyType<ApitypeSetChargingTransactionOrgRequest>;
export type SetchargertransactionorganizationMutationError =
  ErrorType<ApitypeAppError2>;

/**
 * @summary Set charger transaction organization
 */
export const useSetchargertransactionorganization = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useSetchargertransactionorganizationHook>>
    >,
    TError,
    { data: BodyType<ApitypeSetChargingTransactionOrgRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions =
    useSetchargertransactionorganizationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set connect id to digit twin
 */
export const useSetconnectidtodigittwinHook = () => {
  const setconnectidtodigittwin = useOrvalAxiosInstance<void>();

  return (
    apitypeSetConnectorIDRequest: BodyType<ApitypeSetConnectorIDRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return setconnectidtodigittwin(
      {
        url: `/api/v2/skolem/set-connector-id`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: apitypeSetConnectorIDRequest,
      },
      options,
    );
  };
};

export const useSetconnectidtodigittwinMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetconnectidtodigittwinHook>>>,
    TError,
    { data: BodyType<ApitypeSetConnectorIDRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetconnectidtodigittwinHook>>>,
  TError,
  { data: BodyType<ApitypeSetConnectorIDRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const setconnectidtodigittwin = useSetconnectidtodigittwinHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetconnectidtodigittwinHook>>>,
    { data: BodyType<ApitypeSetConnectorIDRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return setconnectidtodigittwin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetconnectidtodigittwinMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetconnectidtodigittwinHook>>>
>;
export type SetconnectidtodigittwinMutationBody =
  BodyType<ApitypeSetConnectorIDRequest>;
export type SetconnectidtodigittwinMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Set connect id to digit twin
 */
export const useSetconnectidtodigittwin = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetconnectidtodigittwinHook>>>,
    TError,
    { data: BodyType<ApitypeSetConnectorIDRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useSetconnectidtodigittwinMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update charger transaction
 */
export const useUpdatechargertransactionHook = () => {
  const updatechargertransaction = useOrvalAxiosInstance<void>();

  return (
    apitypeUpdateChargingTransactionRequest: BodyType<ApitypeUpdateChargingTransactionRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return updatechargertransaction(
      {
        url: `/api/v2/skolem/update-charger-transaction`,
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        data: apitypeUpdateChargingTransactionRequest,
      },
      options,
    );
  };
};

export const useUpdatechargertransactionMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatechargertransactionHook>>>,
    TError,
    { data: BodyType<ApitypeUpdateChargingTransactionRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatechargertransactionHook>>>,
  TError,
  { data: BodyType<ApitypeUpdateChargingTransactionRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updatechargertransaction = useUpdatechargertransactionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatechargertransactionHook>>>,
    { data: BodyType<ApitypeUpdateChargingTransactionRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return updatechargertransaction(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatechargertransactionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatechargertransactionHook>>>
>;
export type UpdatechargertransactionMutationBody =
  BodyType<ApitypeUpdateChargingTransactionRequest>;
export type UpdatechargertransactionMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Update charger transaction
 */
export const useUpdatechargertransaction = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatechargertransactionHook>>>,
    TError,
    { data: BodyType<ApitypeUpdateChargingTransactionRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useUpdatechargertransactionMutationOptions(options);

  return useMutation(mutationOptions);
};
